import { request } from '@umijs/max';

export type LoginType = 'phone' | 'account';

export type LoginParams = {
  username?: string;
  password?: string;
  autoLogin?: boolean;
};

interface LoginData extends SERVICE.RequestData {
  userId?: string;
}

export type UserArray = {
  key: string;
  label: string;
};

export type UserParams = {
  id: number;
  name: string;
  user_name?: string;
  avatar?: string;
  app_id?: string;
  app_secret?: string;
  signature?: string;
  logined_at?: string;
  access: number[];
  role_name?: string[];
  is_lock?: number;
};

/**
 * 登陆接口
 * @param body
 * @param options
 * @returns
 */
export async function loginHandle(
  body: LoginParams,
  options?: { [key: string]: any },
) {
  return request<LoginData>('/admin/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/**
 * 获取用户信息接口
 * @param params
 * @param options
 * @returns
 */
export async function getUserInfo(options?: { [key: string]: any }) {
  return request<SERVICE.RequestData<UserParams | any>>(
    '/admin/login/getUserInfo',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

export default { loginHandle, getUserInfo };
