import { request } from '@umijs/max';

/** 发送公共请求方法 */
export async function send<T = unknown, D = unknown>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  params: T,
  options?: { [key: string]: any },
) {
  return request<D>(url, {
    method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
    params: method === 'GET' ? { ...params } : {},
    data: ['POST', 'PUT', 'PATCH'].includes(method) ? { ...params } : {},
    ...(options || {}),
  });
}
