// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import HomeOutlined from '/usr/local/www/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/HomeOutlined';
import GlobalOutlined from '/usr/local/www/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/GlobalOutlined';
import LineChartOutlined from '/usr/local/www/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/LineChartOutlined';
import TeamOutlined from '/usr/local/www/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/TeamOutlined';
import HddOutlined from '/usr/local/www/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/HddOutlined';
import CodepenOutlined from '/usr/local/www/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/CodepenOutlined';
export default { HomeOutlined, GlobalOutlined, LineChartOutlined, TeamOutlined, HddOutlined, CodepenOutlined };
