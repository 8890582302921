import Footer from '@/components/Footer';
import RightRender from '@/components/RightRender';
import type { UserParams } from '@/services/login';
import service from '@/services/login';
import EscapeAntd, { message } from '@/utils/EscapeAntd';
import type { AxiosResponse, RequestConfig } from '@umijs/max';
import { RunTimeLayoutConfig, RuntimeAntdConfig, history } from '@umijs/max';
import { App } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import React from 'react';

// 运行时配置
export function rootContainer(container: React.ReactNode) {
  return (
    <App>
      <EscapeAntd />
      {container}
    </App>
  );
}

// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://umijs.org/docs/api/runtime-config#getinitialstate
export async function getInitialState(): Promise<UserParams> {
  const jwt = localStorage.getItem('userToken');
  if (jwt && typeof jwt === 'string') {
    let { data, code, msg } = await service.getUserInfo({
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (code === 200) {
      return data;
    }
    message.error(msg);
  }
  history.push('/login');
  return { id: 0, name: '', access: [-1] };
}

/**
 * 返回运行时的antd配置
 * @param memo
 * @returns RuntimeAntdConfig
 */
export const antd: RuntimeAntdConfig = (memo) => {
  memo.locale = zhCN;
  memo.theme = {
    token: {
      borderRadius: 8,
      colorPrimary: '#02407d',
      colorLink: '#2b5677',
    },
  };
  dayjs.locale('zh-cn');
  return memo;
};

/**
 * 返回运行时的layout配置
 * @param initialStateAction
 * @returns RunTimeLayoutConfig
 */
export const layout: RunTimeLayoutConfig = (initialStateAction) => {
  return {
    title: '基础服务可视化平台',
    logo: '/logo.png',
    style: {
      minHeight: '98vh',
    },
    bgLayoutImgList: [
      {
        src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://img.alicdn.com/imgextra/i3/O1CN018NxReL1shX85Yz6Cx_!!6000000005798-2-tps-884-496.png',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    fixSiderbar: true,
    layout: 'mix',
    splitMenus: false,
    siderWidth: 216,
    rightRender: (initialState: any) => (
      <RightRender action={initialStateAction} initialState={initialState} />
    ),
    // 默认布局调整
    footerRender: Footer,
  };
};

// 枚举错误代码
enum CodeType {
  SUCCESS = 200,
  ERROR = 400,
  REDIRECT = 301,
  SYSTEMERROR = 500,
}

// Request运行时配置
export const request: RequestConfig = {
  timeout: 3600 * 10,
  headers: {
    'Content-Type': 'application/json',
  },
  responseInterceptors: [
    (response: AxiosResponse<any>) => {
      // 拦截响应数据，进行个性化处理
      const data = response.data as SERVICE.RequestData;
      if (!data.success) {
        switch (data.code) {
          case CodeType.ERROR:
          case CodeType.SYSTEMERROR:
            message.error(data.msg);
            break;
          case CodeType.REDIRECT:
            message.error(data.msg);
            history.push(
              `/login?redirect=${encodeURI(history.location.pathname)}`,
            );
            break;
        }
      }
      return response;
    },
  ],
};
