import { send } from '@/services/request';
import { message } from '@/utils/EscapeAntd';
import { LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { history } from '@umijs/max';
import type { MenuProps } from 'antd';
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Space,
  Typography,
} from 'antd';
import React, { useState } from 'react';

const rightRenderItems: MenuProps['items'] = [
  {
    key: 'user',
    label: <a>重置密码</a>,
    icon: <LockOutlined />,
  },
  {
    type: 'divider',
  },
  {
    key: 'logout',
    label: <a>退出登陆</a>,
    icon: <LogoutOutlined />,
  },
];

const RightRender: React.FC<{ action: any; initialState: any }> = ({
  action,
  initialState,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [form] = Form.useForm();

  // 重置密码
  const handlePasswordSubmit = () => {
    form.validateFields().then(async (values: { password: string }) => {
      let { success, msg } = await send<
        { id: number; password: string },
        SERVICE.RequestData
      >('admin/user/setPassword', 'PATCH', {
        id: initialState.id,
        password: values.password,
      });
      if (success) {
        localStorage.removeItem('userToken');
        history.push(`/login?redirect=${encodeURI(history.location.pathname)}`);
        message.success(msg);
        return;
      }
      message.error(msg);
    });
  };

  return (
    <>
      <Dropdown
        menu={{
          items: rightRenderItems,
          onClick: ({ key }) => {
            switch (key) {
              case 'logout':
                action.setInitialState({ name: '', avatar: '' });
                localStorage.removeItem('userToken');
                history.push(
                  `/login?redirect=${encodeURI(history.location.pathname)}`,
                );
                break;
              case 'user':
                setShow(true);
                break;
              default:
                action.refresh();
                break;
            }
          },
        }}
        placement="bottomRight"
      >
        <Button type="text" size="large" style={{ paddingTop: 4 }}>
          <Space
            style={{
              cursor: 'pointer',
            }}
          >
            <Avatar
              size="default"
              src={
                initialState.avatar ??
                'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg'
              }
              alt="avatar"
            />
            <Typography.Text>{initialState.name}</Typography.Text>
          </Space>
        </Button>
      </Dropdown>
      <Modal
        open={show}
        title="修改密码"
        onCancel={() => {
          form.resetFields();
          setShow(false);
        }}
        onOk={handlePasswordSubmit}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          style={{ marginTop: 12 }}
        >
          <Form.Item<{ password: string }>
            label="新密码"
            name="password"
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <Input placeholder="输入密码" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RightRender;
