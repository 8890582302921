import type { UserParams } from '@/services/login';

export default (initialState: UserParams) => {
  // 在这里按照初始化数据定义项目中的权限，统一管理
  // 参考文档 https://umijs.org/docs/max/access
  // 超级管理员
  const canAdmin = !!(
    initialState &&
    initialState.access &&
    initialState.access.includes(1)
  );
  // 查看第三方记录
  const canCheckApi =
    (initialState && initialState.access && initialState.access.includes(2)) ||
    canAdmin;
  return {
    canAdmin,
    canCheckApi,
  };
};
